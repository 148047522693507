    <template>
     <div style = "height:100%; background-color: #ffffff" class="d-flex justify-content-center align-items-center" > 
      <div class = "container"  v-if="pageloaded">

        <div id="signinpage"> 
         <p class="logo"><a href="https://flowe.io"><img src="@/static/img/flowe.png" class="imglogo"></a></p>

         <div class="responsepanel mt-3">
          
          <div class="response text-danger" v-if="!sessionisvalid" >Your session has expired. To continue, please sign in again.</div>
          <div class="response text-danger " v-if="redirect!=''" style="width: 330px; margin: auto; text-align: justify;">Oops! The page you are trying to access is only available to registered users. Click below to sign in, or sign up for an account if you don’t already have one.</div>
          
        </div>

         <div class="pagetitle">
          <h2 class = "text-center">Sign in</h2>
        </div>        


          

        <div class="form_wrapper">

          <form onsubmit="return false;">

            <!-- <div class="text-danger" v-if="signin.signinlocked">Account has been temporarily locked. Please try again after 15 minutes.</div> -->

            <div class="form-group">
              <label>Email</label>
              <input type="text" class="form-control tabable" aria-describedby="emailHelp" placeholder="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'email'" autocomplete="new-password" v-model="signin.email" tabindex=1 >                
              <div class="text-danger" v-if="signin.errors.email == 'emailinvalid'">Please enter a valid email address</div>
              <div class="text-danger" v-if="signin.errors.email == 'accessinvalid'">Invalid email address or password</div>

            </div>
            <div class="form-group">
              <label>Password</label>
              <input type="Password" class="hide" value="" name="dummypassword">
              <input type="Password" class="form-control tabable" value="" autocomplete="new-password" placeholder="Password" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Password'" v-model="signin.password" tabindex=2>
              <div class="text-danger" v-if="signin.errors.password=='passwordempty'">Please enter your password</div>
            </div>

            <div class="form-group" style = "display: flex;" >
              <input id="checkbox" type="checkbox"  name="remember" checked="checked" value="on" v-model="remember">
              <label id="checkboxlabel" for="checkbox" tabindex=3 ></label>
              <label>Keep me signed in for 30 days</label>
            </div>

            <div class="form-group">
              <button id = "signinwithemail" v-if="status=='idle'" class="btn btn-block btn-lg btn-primary tabable" v-on:click="dosignin()" tabindex=4 >Sign in</button>
              <button v-if="status=='working'" type="button" id = "signinwithemail" class="btn btn-block btn-lg btn-primary disabled tabable" disabled>Sign in <i class="fa fa-circle-o-notch fa-spin"></i></button>
            </div>
            
          </form>
          <div class="form-group">
            <p class="text-center mt-2">
                <button type = "nostyle" id = "forgotpassword" class="actionwarning tabable" @click =" linkclick('forgot-password')" tabindex=5>
                Forgot password? </button>
              </p>
          </div>
          <div class="form-group">    
              <p class="text-center">or</p>
            </div>
          <div class="form-group">
           <GoogleLogin id = "signinwithgoogle" :params="params" :onSuccess="onSuccess" :onFailure="onFailure" class="btn btn-block btn-lg btn-google tabable" tabindex = '6'> <img src="@/static/img/glogo.png" style="width: 24px; height: 24px;"> Sign in with Google </GoogleLogin>  
         </div>
         <div class="form-group text-center">

          

        <router-link :id="'signin'" :to="'/sign-in-sso'" class="tabable actionprimary"><i class="fas fa-key"></i>  Sign in with SSO</router-link>



         </div>

          <div class ="mt-2 diverder"></div>
          
         <div class="form-group">
           <p class="text-center">
            <button type="nostyle" id="signup" class="actionwarning tabable" @click="linkclick('sign-up')" tabindex="7" >Don't have an account?  Sign up</button >
          </p>
        </div> 
      </div>


    </div>
  </div>

  <b-modal v-model="nogooglesignin" hide-header size="sm-p" >
   <div>

    <p v-if="isregularaccount">Your Google email address is already being used. Sign in using this email as a regular Flowe email account</p>
    <p v-if="isregularaccount==false">We did not find a Flowe account associated with the Google account you are currently logged in with. Click the button to create a new account using this Google account.</p>

    <p v-if="isregularaccount==false">If you already have a Flowe account and would like to connect it to your Google account, then you can do that from the My Profile page after you have signed in using your email and password.</p>
  </div>
  <template v-slot:modal-footer="{ ok }">
   <button v-if="isregularaccount" type="button" @click="nogooglesignin=false" class="btn btn-primary">Ok</button>
   <button v-if="!isregularaccount" type="button" @click="gotosignup()" class="btn btn-primary">Sign up</button>
 </template>
</b-modal>

<b-modal v-model="signinlockedmodal" hide-header size="sm-p" >
  <button type="button" aria-label="Close" style = "float: right" @click="signinlockedmodal = false" class="close"> <span aria-hidden="true">&times;</span> </button>
  <div class="bootbox-body">
    <br>You have entered the wrong username or password 10 times. 
    <br>For security reasons, this account ({{signin.email}}) has been temporarily locked. <br>
    <br> Please try again after 15 minutes.
  </div>
  <template v-slot:modal-footer="{ ok }">
   <button type="button" @click="signinlockedmodal=false" class="btn btn-primary">OK</button>
 </template>
</b-modal>

</div>
</template>	
<style type="text/css" scoped>
:root{

  /* --G700: #157815; */
  --N900: #313338;
}


html,
body {
  color: var(--N900);
  height: 100%;
  overflow:hidden;
}

h1, h2{ text-align: center; }

.pagetitle{
  width: 100%;
  margin: 0 auto;
  max-width: 400px;
  margin-bottom: 1rem;
  margin-top: 3%;
}


.form_wrapper{
  margin: 0 auto;
  max-width: 350px;
}

button[type="nostyle"]:focus {outline:  dotted 1px;}

#signuppage .form_wrapper,
#passwordreset .form_wrapper{ 
  max-width: 355px; 
}

#signuppage2 .form_wrapper{ max-width: 400px; }

#responsepage .signuppage .pagetitle{
  max-width: 500px;
}

.btn-google img{ 
  width: 24px;
  height: 24px;
  margin-right:.3em; 
}

p.logo .imglogo{ 
  width: 200px; 
}
p.logo{
  text-align: center;
  width: 100%;
  margin: auto;
}

input[type="checkbox"]{
        outline:0;
        border: 1px solid #000 !important;
    }

</style>

<script type="text/javascript"></script>
<script>



  import Signinapi from '@/services/api/signin'
  import Userapi from '@/services/api/users'

  import GoogleLogin from 'vue-google-login';

  import store from '@/store'
  import auth from '@/cognito.js';

  //nwjC8b2Q12YqHCRx70f4BfYw
  export default{
    data(){
      return{
        gauth : null,
        remember : true,
        signinlockedmodal: false,
        status : 'idle',
        userid : null,
        sessionisvalid:true,
        redirect:"",
        orgkey:"",
        jwtinfo : {},
        idp : {},
        samlerror : "",
        samluser : "",
        signin : { email:"", password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false, errors:{} },
        params: {
          client_id: "957046757712-8onubrnl55fk21ihr4m5hd243fcbkaq2.apps.googleusercontent.com",
          class : "btn btn-block btn-lg btn-google"
        },
            // only needed if you want to render the button with the google ui
            renderParams: {
              width: 250,
              height: 50,
              longtitle: true
            },
            pageloaded : false,
            nogooglesignin : false,
            isregularaccount : false,
            keepmesignedin: true,
            lable: null,
            userfoundbutincomplete: false,
            userfound : false



          }
        },
        computed: {

          samldata(){

    return this.$store.getters.saml ? this.$store.getters.saml : {}
  },

          isuserauth(){

            return auth.auth.isUserSignedIn()
          },

          userinfo(){
            return this.$store.getters.user
          },
      
        },
        filters: {},
         watch:{

          isuserauth( to,from ){
            console.log('auth')
          }

          },

        mounted(){



          if( this.$route.query.change ){

            this.$cookie.set('usertoken', '');
            this.$cookie.set('organizationkey', '');
            this.$cookie.set('signinmethod', "");

            auth.logout();

            setTimeout( function(){
              location.href="/"
            }, 500)

            //document.write("")
          }

          let self = this

          var usetoken = this.$route.query.usetoken

          if (auth.auth.isUserSignedIn()) {

            var authuser = auth.auth.getSignInUserSession();

            //console.log( authuser.idToken, 'id token' )
            //console.log( authuser.refreshToken, 'ref token' )

            
            //console.log( authuser.idToken.payload.zoneinfo )
            var subjectid = ""

            if( authuser.idToken.payload.zoneinfo ){
              subjectid = authuser.idToken.payload.zoneinfo
              this.samluser = authuser.idToken.payload.email
            }

            Signinapi.Flowetoken(  subjectid ).then(response => {

              if( response.data.user ){

                var usertoken = response.data.user.uuid
                self.$cookie.set('usertoken', usertoken);
                self.$cookie.set('signinmethod', "saml");
                self.gotonextpage()

              }else{
                self.samlerror = "validsamlinvaliduser"
                //console.log("SAML is valid. user not found check object id if valid")
              }



            })

          }else{

            //if( auth.auth.getSignInUserSession().getRefreshToken().refreshToken ){
            //  auth.auth.refreshSession(auth.auth.getSignInUserSession().getRefreshToken().refreshToken)
            //}

             

          }

          /*

          if (auth.auth.isUserSignedIn()) {

            var jwt = Signinapi.getjwtUserInfo()

            jwt.then( function(response){

             self.jwtinfo = response

             if( !response.email ){

              self.samlerror = "samlincomplete"

             }else{

               var email = response.email.toLowerCase()
               var sub = response.sub

               Signinapi.Emailtosso( email, sub ).then( function(response){

               console.log(  response.data.user , "xxxx")

               self.idp = response.data.idp

               if( response.data.user && response.data.user.email != '' ){
                self.userfound = true
               }

                if( response.data.user && !response.data.user.credential && response.data.user.email != '' ){
                  self.userfoundbutincomplete = true
                }

                /*for( var i in response.data.user.signinmethods ){

                 if( response.data.user.signinmethods[i].subject == self.jwtinfo.sub ){

                  var usetoken = response.data.user.uuid

                  localStorage.token = usetoken


                  self.gotonextpage()

                  return
                 }

                }

                 



               })

             }



            })

          }

          */

          

          if( usetoken != "" && usetoken != undefined ){
            this.$cookie.set('usertoken', usetoken);
          }


          document.title = 'Flowe | Sign in'

          var redirect = this.$route.query.redirect + ''


          if( redirect != '' && redirect != 'undefined'  ){
            this.redirect = redirect
          }
          

          var usertoken = this.$cookie.get('usertoken');
          var organizationkey = this.$cookie.get('organizationkey');

          //alert( usertoken )

          //alert(usertoken)
           if( organizationkey && organizationkey != "" && ( usertoken == null || usertoken == "" )  ){

              this.$cookie.set('organizationkey', '');
              organizationkey = ""
           }


          if( !organizationkey || organizationkey == "" ){

            store.dispatch('fecthUserDetails', usertoken).then( function(response){

     
              if( response.data.ok ){

                localStorage.token = usertoken

                //console.log( response.data.user.userInfo.organization.key )
                self.orgkey = response.data.user.organization.key
                self.$cookie.set('organizationkey', self.orgkey );

                self.pageloaded = false
                self.gotonextpage()
                
              }

            })
            .catch(error => {

              this.$cookie.set('usertoken', '');
              this.$cookie.set('organizationkey', '');

            })
            .finally( () => {
              self.pageloaded = true
            })

          }else{

            if( usertoken != "" && organizationkey != "" ){

              this.orgkey = organizationkey

              this.pageloaded = false
              this.gotonextpage()

            }else{
              this.pageloaded = true
            }


          }
         
              // Get the input field

            // Execute a function when the user releases a key on the keyboard

            window.addEventListener("keyup", function(event) {
              // Number 13 is the "Enter" key on the keyboard
              if (event.keyCode === 13) {
                document.getElementById('signinwithemail').click()
                //;
              }

              if(event.keyCode === 32){
                $('#checkboxlabel:focus').click();
              } 
            });

            $('#preloader').addClass('hide')
            

          },
          methods: {

            generatePassword() {
              var length = 4
              var charset = "abcdefghijklmnopqrstuvwxyz"
              var retVal = "";              
              for (var i = 0, n = charset.length; i < length; ++i) {
                retVal += charset.charAt(Math.floor(Math.random() * n));
              }

              charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
              
              for (var i = 0, n = charset.length; i < length; ++i) {
                retVal += charset.charAt(Math.floor(Math.random() * n));
              }

              charset = "0123456789"
              
              for (var i = 0, n = charset.length; i < length; ++i) {
                retVal += charset.charAt(Math.floor(Math.random() * n));
              }
              return retVal;
            },

            completesignup(){

              var info = this.jwtinfo

              /*Signinapi.Completessosignup( { "action":"signupsso", "sub":info.sub,"email":info.email,"firstname":info.given_name,"lastname":info.family_name } ).then(response => { 
                  console.log(response)
              });*/

              var email = info.email
              var firstname = info.given_name
              var lastname = info.family_name
              var sub = info.sub

              var password = this.generatePassword()
              var password2 = password

              let self = this
 
              Signinapi.Registerdo( { "format":"token", "idp":this.idp.domain, "sub":sub, "email":email.toLowerCase(), "firstname":firstname, "lastname":lastname, "password":password, "password2":password2, "v":"" } ).then(response => {  

               // console.log( response )

                var usertoken = response.data.response
                self.$cookie.set('usertoken', usertoken);

                // alert(usertoken)         

                self.gotonextpage()      


              });
            },

            getuserdetails( usertoken ){




            },
            linkclick(link){
              //location.href='/'+link
              //router.push({ path: 'sign-up' })
              this.$router.push('/'+link)
              //location.href='/'+link
            },
            //<router-link :id="intro" :to="'/boards/'+cockpit.ID" class="card-block clearfix">

            gotonextpage(){

              if( this.redirect != "" ){
                location.href=this.redirect
                //this.$router.push( this.redirect )
              }else{

                if( this.orgkey ){
                  location.href= '/'+this.orgkey+'/boards'
                  //this.$router.push( '/'+this.orgkey+'/boards' )
                }else{
                  //this.$router.push( '/boards' )
                  location.href= '/boards'
                }
                
              }

            },

            gotosignup(){
              this.nogooglesignin=false; 
              //location.href='/sign-up'

              //router.push({ path: 'sign-up' })
              this.$router.push('/sign-up')

            },

            onSuccess : function( googleUser ){

              let self = this

              this.isregularaccount = false

              //var profile = googleUser.getBasicProfile();      
              var id_token = googleUser.getAuthResponse().id_token;

              Signinapi.Signinwithgoogle( id_token )
              .then( function(response){


                self.remember = true

                if( response.data.ok ){

                  //response.data.user.organization.keyself.orgkey =  
                  self.orgkey = ""
                  self.$cookie.set('usertoken', response.data.response, { expires: '1M' } );
                  //self.$cookie.set('usertoken', response.data.response);
                  //self.$router.push('/boards')
                  self.gotonextpage()

                }else{

                  if( response.data.account != "" ){
                    self.isregularaccount = true
                  }
                  self.nogooglesignin = true
                }

              })
              .catch(error => console.log(error))
              .finally( () => {

                self.status = 'idle'

              })



            },
            onFailure : function( rep ){

              console.log( rep, "error")

            },

            dosignin : function(){


              localStorage.token = ""
              localStorage.userdetails = ""

              let self = this

              self.status = 'working'

              Signinapi.Signin( { email: self.signin.email, password: self.signin.password } )
              .then( function(response){
                if( response.data.ok ){    

                  if( self.remember == true ){
                    self.$cookie.set('usertoken', response.data.response, { expires: '1M' } );
                  }else{
                    self.$cookie.set('usertoken', response.data.response );
                  }             

                  self.$cookie.set('showorghelp', "yes");

                  self.gotonextpage()           

                }else{

                  if( response.data.ErrorMessage ){
                    self.signin.errors = response.data.ErrorMessage
                    
                  }

                  if( response.data.locked ){
                    self.signin.signinlocked = true
                    self.signinlockedmodal = true
                  }

                  //signinisvalid
                  //console.log( response.data.ErrorMessage.email, response.data.ErrorMessage.password )
                }
              })
              .catch(error => console.log(error))
              .finally( () => {

                self.status = 'idle'
                self.signin.password = ''
                //do everything
                //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
              })

            }
          },
          components : { GoogleLogin }
        }
      </script>